.App {
  text-align: left;
  background-color: white;
}

.menu-link {
  color: #000000;
  opacity: 87%;
  text-decoration: none;
}

.nav-button {
  width: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.calculating  circle {
    opacity: 0.8;
}

#row1 {  animation: row1animation .3s linear infinite}
#row2 {  animation: row2animation .3s linear infinite}
#row3 {  animation: row3animation .3s linear infinite}

.cc1 {  animation: colorchange1 .33s linear infinite}
.cc2 {  animation: colorchange2 .32s linear infinite}
.cc3 {  animation: colorchange3 .35s linear infinite}

@keyframes row1animation {
  0%   { transform: translate(0px,  0px);}
  10%  { transform: translate(0px, 10px);}
  50%  { transform: translate(0px, 20px);}
  70%  { transform: translate(0px, 10px);}
  100% { transform: translate(0px,  0px);}
}

@keyframes row2animation {
  0%   { transform: translate(0px, 10px);}
  20%  { transform: translate(0px,  0px);}
  50%  { transform: translate(0px, 10px);}
  80%  { transform: translate(0px, 20px);}
  100% { transform: translate(0px, 10px);}
}

@keyframes row3animation {
  0%   { transform: translate(0px, 20px);}
  30%  { transform: translate(0px, 10px);}
  60%  { transform: translate(0px,  0px);}
  75%  { transform: translate(0px, 10px);}
  100% { transform: translate(0px, 20px);}
}

@keyframes colorchange1 {
  0%   { fill: #67adec; transform: translate(6px, 2px); }
  20%   { fill: #f3f3fb; transform: translate(-3px, -2px); }
  40%   { fill: #494953; transform: translate(0px, 0px); }
  60%   { fill: #68686c; transform: translate(5px, 0px); }
  80%   { fill: #3a3335; transform: translate(-2px, 0px); }
  100%   { fill: #67adec; transform: translate(6px, 2px); }
}


@keyframes colorchange2 {
  0%   { fill: #68686c; transform: translate(5px, 0px); }
  20%   { fill: #3a3335; transform: translate(-2px, 0px); }
  40%   { fill: #67adec; transform: translate(6px, 2px);}
  60%   { fill: #68686c; transform: translate(5px, 0px); }
  80%   { fill: #cacad4; transform: translate(0px, 0px); }
  100%   { fill: #68686c; transform: translate(5px, 0px); }
}



@keyframes colorchange2 {
  0%   { fill: #494953; transform: translate(0px, 0px); }
  20%   { fill: #3a3335; transform: translate(-2px, 0px); }
  40%   { fill: #f3f3fb; transform: translate(-3px, -2px); }
  60%   { fill: #68686c; transform: translate(5px, 0px); }
  80%   { fill: #67adec; transform: translate(6px, 2px); }
  100%   { fill: #494953; transform: translate(0px, 0px); }
}
